import FormValidationDo from "../../../../src/content/resources/images/form_validation_do.gif";
import FormValidationDont from "../../../../src/content/resources/images/form_validation_dont.gif";
import FormErrorPositionDont from "../../../../src/content/resources/images/form_error_position_dont.png";
import FormErrorPositionSectionDo from "../../../../src/content/resources/images/form_error_position_section_do.png";
import FormErrorPositionSectionDont from "../../../../src/content/resources/images/form_error_position_section_dont.png";
import FormErrorPositionOtherDo from "../../../../src/content/resources/images/form_error_position_other_do.png";
import FormScrollingDo from "../../../../src/content/resources/images/form_scrolling_do.gif";
import FormScrollingDont from "../../../../src/content/resources/images/form_scrolling_dont.gif";
import FormLoadingDo from "../../../../src/content/resources/images/form_loading_do.gif";
import FormLoadingDont from "../../../../src/content/resources/images/form_loading_dont.gif";
import * as React from 'react';
export default {
  FormValidationDo,
  FormValidationDont,
  FormErrorPositionDont,
  FormErrorPositionSectionDo,
  FormErrorPositionSectionDont,
  FormErrorPositionOtherDo,
  FormScrollingDo,
  FormScrollingDont,
  FormLoadingDo,
  FormLoadingDont,
  React
};