import BlockStructure from "../../../../src/content/resources/images/block-structure.png";
import block1 from "../../../../src/content/resources/images/block-1.png";
import block2 from "../../../../src/content/resources/images/block-2.png";
import block3 from "../../../../src/content/resources/images/block-3.png";
import blockActions1 from "../../../../src/content/resources/images/block-actions-1.png";
import blockActions2 from "../../../../src/content/resources/images/block-actions-2.png";
import blockActions3 from "../../../../src/content/resources/images/block-actions-3.png";
import blockActions4 from "../../../../src/content/resources/images/block-actions-4.png";
import blockActions5 from "../../../../src/content/resources/images/block-actions-5.png";
import destructive1Dont from "../../../../src/content/resources/images/destructive_1_dont.png";
import destructive1Do from "../../../../src/content/resources/images/destructive_1_do.png";
import destructive2Do1 from "../../../../src/content/resources/images/destructive_2_do_1.png";
import destructive2Do2 from "../../../../src/content/resources/images/destructive_2_do_2.png";
import destructive2Dont1 from "../../../../src/content/resources/images/destructive_2_dont_1.png";
import * as React from 'react';
export default {
  BlockStructure,
  block1,
  block2,
  block3,
  blockActions1,
  blockActions2,
  blockActions3,
  blockActions4,
  blockActions5,
  destructive1Dont,
  destructive1Do,
  destructive2Do1,
  destructive2Do2,
  destructive2Dont1,
  React
};