import React from 'react';

export default function Bullhorn() {
  return (
    <svg width="57" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.9 22.6v21.5L37 41.2V19.8l4.9 2.8Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinejoin="bevel"
      />
      <path
        d="M41.9 22.6V44l4.9-2.8V19.8l-5 2.8Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinejoin="bevel"
      />
      <path
        d="M15.8 12c4.1 2.4 7.9 6.5 10.6 11.2 2.7 4.7 4.4 10 4.4 14.8 0 4.7-1.7 8-4.3 9.6C23.8 49 20 48.9 16 46.5A30.6 30.6 0 0 1 5.4 35.3C2.7 30.6 1 25.3 1 20.6c0-4.8 1.7-8 4.3-9.6 2.7-1.5 6.4-1.3 10.5 1Z"
        stroke="#000"
        strokeWidth=".3"
      />
      <path
        d="m7.1 10-.7.4c2.6-.9 5.8-.4 9.3 1.7 4.1 2.4 7.9 6.5 10.6 11.2 2.7 4.6 4.4 10 4.4 14.7h-.2c0 4.6-1.5 7.8-4 9.5L7.1 10Zm0 0c2.7-1.6 6.4-1.5 10.6 1 4 2.3 7.8 6.4 10.5 11.1 2.8 4.7 4.4 10 4.4 14.8 0 4.8-1.7 8.1-4.3 9.6l-.8.4L7.1 10Z"
        stroke="#000"
        strokeWidth=".3"
      />
      <path
        d="M15.8 14.9c3.5 2 6.6 5.4 8.9 9.3 2.2 4 3.6 8.4 3.6 12.4s-1.4 6.7-3.6 8c-2.2 1.3-5.3 1-8.7-1-3.5-1.9-6.6-5.3-8.9-9.3C5 30.4 3.5 26 3.5 22s1.4-6.7 3.6-8c2.2-1.3 5.3-1.1 8.7.9Z"
        fill="#EFEAE8"
        stroke="#000"
        strokeWidth=".3"
      />
      <path
        d="M56.5 13.7c0 2.2-.8 3.8-2 4.6l-7.4 4c-1.3.7.3-1-1.7-2.2a16 16 0 0 1-7.2-12.5c0-2.3-2.1-1.8-.8-2.5L44.2.6c1.3-.7 3-.6 5 .6a16 16 0 0 1 7.3 12.5Z"
        fill="#ED914A"
        stroke="#000"
        strokeWidth=".3"
        strokeMiterlimit="10"
      />
      <path
        d="M48.6 18c0 2.2-.8 3.8-2 4.5l-2.8 1.6c-6.6 3.9-8.2 9-11.8 17.3.8-2.4 1-8.7-1.5-14.5a36 36 0 0 0-10.3-14.1 17 17 0 0 0-8-3.8c9 .7 14.2 2.3 20.7-1.4l3.8-2.2c1.2-.7 3-.6 4.9.5 3.8 2.3 7 7.7 7 12.2Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeMiterlimit="10"
      />
      <path
        d="m16.2 27.2 6.6-5.7c3 4.4 4.2 7.4 4.4 8.4l-7.7 2.9c-.4.2-.1-.3-.8-.7a5 5 0 0 1-2.3-4c0-.7-.6-.7-.2-1Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeMiterlimit="10"
      />
      <path
        d="M19.4 32.8c1-.5 1-2.2 0-3.7-.9-1.6-2.3-2.4-3.2-1.9-1 .5-1 2.2 0 3.8.9 1.5 2.4 2.4 3.2 1.8Z"
        fill="#ED914A"
        stroke="#000"
        strokeWidth=".3"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
