import React from 'react';

const DocsContext = React.createContext({
  defaultShowsCode: false,
  setDefaultShowsCode: v => {},
  defaultComponentTab: '',
  setDefaultComponentTab: v => {},
  defaultPropsSort: '',
  setDefaultPropsSort: v => {},
  userRole: '',
  setUserRole: v => {},
  editorStyle: '',
  setEditorStyle: v => {},
  userName: '',
  setUserName: v => {},
  updateAvailable: false,
});

export default DocsContext;
