import React from 'react';
import DocsContext from './src/contexts/DocsContext';
import useLocalStorage from './src/hooks/useLocalStorage';

const Wrapper = props => {
  const [defaultShowsCode, setDefaultShowsCode] = useLocalStorage('defaultShowsCode', false);
  const [defaultComponentTab, setDefaultComponentTab] = useLocalStorage('defaultComponentTab', 'examples');
  const [defaultPropsSort, setDefaultPropsSort] = useLocalStorage('defaultPropsSort', 'significance');
  const [userRole, setUserRole] = useLocalStorage('userRole', '');
  const [editorStyle, setEditorStyle] = useLocalStorage('editorStyle', 'light');
  const [userName, setUserName] = useLocalStorage('userName', '');
  const [updateAvailable, setUpdateAvailable] = React.useState(false);

  React.useEffect(() => {
    window.INK_NOTIFY_NEW_VERSION = () => setUpdateAvailable(true);
    return () => {
      window.INK_NOTIFY_NEW_VERSION = undefined;
    };
  });

  const value = {
    defaultShowsCode,
    setDefaultShowsCode,
    defaultComponentTab,
    setDefaultComponentTab,
    defaultPropsSort,
    setDefaultPropsSort,
    userRole,
    setUserRole,
    editorStyle,
    setEditorStyle,
    userName,
    setUserName,
    updateAvailable,
  };

  return <DocsContext.Provider value={value}>{props.children}</DocsContext.Provider>;
};
export const wrapRootElement = ({ element }) => {
  return <Wrapper>{element}</Wrapper>;
};

export const onServiceWorkerUpdateFound = () => {
  typeof window !== 'undefined' && window.INK_NOTIFY_NEW_VERSION && window.INK_NOTIFY_NEW_VERSION();
};
