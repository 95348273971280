import pageRegions from "../../../../src/content/resources/images/page-layout-page-regions.png";
import pageRegionsMobile from "../../../../src/content/resources/images/page-layout-page-regions-mobile.png";
import headerRegion from "../../../../src/content/resources/images/page-layout-header-region.png";
import headerTitles from "../../../../src/content/resources/images/page-layout-header-titles.png";
import headerBreadcrumbs from "../../../../src/content/resources/images/page-layout-header-breadcrumbs.png";
import headerInserts from "../../../../src/content/resources/images/page-layout-header-inserts.png";
import headerNavigation from "../../../../src/content/resources/images/page-layout-header-navigation.png";
import headerDivider from "../../../../src/content/resources/images/page-layout-header-divider.png";
import headerResponsive from "../../../../src/content/resources/images/page-layout-header-responsive.png";
import footerFunctional from "../../../../src/content/resources/images/page-layout-footer-functional.png";
import footerSequential from "../../../../src/content/resources/images/page-layout-footer-sequential.png";
import navigationVertical from "../../../../src/content/resources/images/page-layout-navigation-vertical.png";
import navigationHorizontal from "../../../../src/content/resources/images/page-layout-navigation-horizontal.png";
import navigationSubnav from "../../../../src/content/resources/images/page-layout-navigation-subnav.png";
import navigationStep from "../../../../src/content/resources/images/page-layout-navigation-step.png";
import contentSectionBasic from "../../../../src/content/resources/images/page-layout-content-section-basic.png";
import contentSectionWideActions from "../../../../src/content/resources/images/page-layout-content-section-wide-actions.png";
import contentSectionFull from "../../../../src/content/resources/images/page-layout-content-section-full.png";
import contentSectionInsert from "../../../../src/content/resources/images/page-layout-content-section-insert.png";
import contentSectionAccessories from "../../../../src/content/resources/images/page-layout-content-section-accessories.png";
import contentSectionAccessoriesOpen from "../../../../src/content/resources/images/page-layout-content-section-accessories-mobile-open.png";
import contentSectionOutline from "../../../../src/content/resources/images/page-layout-content-section-outline.png";
import accessoriesEnd from "../../../../src/content/resources/images/page-layout-accessories-end.png";
import accessoriesStartMobile from "../../../../src/content/resources/images/page-layout-accessories-start-mobile.png";
import accessoriesLarge from "../../../../src/content/resources/images/page-layout-accessories-large.png";
import accessory from "../../../../src/content/resources/images/page-layout/common-layouts/Accessory.png";
import accessoryWide from "../../../../src/content/resources/images/page-layout/common-layouts/Accessory-wide.png";
import accessoryFull from "../../../../src/content/resources/images/page-layout/common-layouts/Accessory-full.png";
import accessoryStart from "../../../../src/content/resources/images/page-layout/common-layouts/AccessoryStart.png";
import accessoryStartWide from "../../../../src/content/resources/images/page-layout/common-layouts/AccessoryStart-wide.png";
import accessoryStartFull from "../../../../src/content/resources/images/page-layout/common-layouts/AccessoryStart-full.png";
import contentOnly from "../../../../src/content/resources/images/page-layout/common-layouts/ContentOnly.png";
import contentOnlyWide from "../../../../src/content/resources/images/page-layout/common-layouts/ContentOnly-wide.png";
import contentOnlyFull from "../../../../src/content/resources/images/page-layout/common-layouts/ContentOnly-full.png";
import horizontalNav from "../../../../src/content/resources/images/page-layout/common-layouts/HorizontalNav.png";
import horizontalNavWide from "../../../../src/content/resources/images/page-layout/common-layouts/HorizontalNav-wide.png";
import horizontalNavFull from "../../../../src/content/resources/images/page-layout/common-layouts/HorizontalNav-full.png";
import horizontalNavWithAccessory from "../../../../src/content/resources/images/page-layout/common-layouts/HorizontalNavWithAccessory.png";
import horizontalNavWithAccessoryWide from "../../../../src/content/resources/images/page-layout/common-layouts/HorizontalNavWithAccessory-wide.png";
import horizontalNavWithAccessoryFull from "../../../../src/content/resources/images/page-layout/common-layouts/HorizontalNavWithAccessory-full.png";
import verticalOrSteppedNavWithAccessory from "../../../../src/content/resources/images/page-layout/common-layouts/VerticalOrSteppedNavWithAccessory.png";
import verticalOrSteppedNavWithAccessoryWide from "../../../../src/content/resources/images/page-layout/common-layouts/VerticalOrSteppedNavWithAccessory-wide.png";
import verticalOrSteppedNavWithAccessoryFull from "../../../../src/content/resources/images/page-layout/common-layouts/VerticalOrSteppedNavWithAccessory-full.png";
import horizontalNavWithAccessoryStart from "../../../../src/content/resources/images/page-layout/common-layouts/HorizontalNavWithAccessoryStart.png";
import horizontalNavWithAccessoryStartWide from "../../../../src/content/resources/images/page-layout/common-layouts/HorizontalNavWithAccessoryStart-wide.png";
import horizontalNavWithAccessoryStartFull from "../../../../src/content/resources/images/page-layout/common-layouts/HorizontalNavWithAccessoryStart-full.png";
import verticalOrSteppedNav from "../../../../src/content/resources/images/page-layout/common-layouts/VerticalOrSteppedNav.png";
import verticalOrSteppedNavWide from "../../../../src/content/resources/images/page-layout/common-layouts/VerticalOrSteppedNav-wide.png";
import verticalOrSteppedNavFull from "../../../../src/content/resources/images/page-layout/common-layouts/VerticalOrSteppedNav-full.png";
import * as React from 'react';
export default {
  pageRegions,
  pageRegionsMobile,
  headerRegion,
  headerTitles,
  headerBreadcrumbs,
  headerInserts,
  headerNavigation,
  headerDivider,
  headerResponsive,
  footerFunctional,
  footerSequential,
  navigationVertical,
  navigationHorizontal,
  navigationSubnav,
  navigationStep,
  contentSectionBasic,
  contentSectionWideActions,
  contentSectionFull,
  contentSectionInsert,
  contentSectionAccessories,
  contentSectionAccessoriesOpen,
  contentSectionOutline,
  accessoriesEnd,
  accessoriesStartMobile,
  accessoriesLarge,
  accessory,
  accessoryWide,
  accessoryFull,
  accessoryStart,
  accessoryStartWide,
  accessoryStartFull,
  contentOnly,
  contentOnlyWide,
  contentOnlyFull,
  horizontalNav,
  horizontalNavWide,
  horizontalNavFull,
  horizontalNavWithAccessory,
  horizontalNavWithAccessoryWide,
  horizontalNavWithAccessoryFull,
  verticalOrSteppedNavWithAccessory,
  verticalOrSteppedNavWithAccessoryWide,
  verticalOrSteppedNavWithAccessoryFull,
  horizontalNavWithAccessoryStart,
  horizontalNavWithAccessoryStartWide,
  horizontalNavWithAccessoryStartFull,
  verticalOrSteppedNav,
  verticalOrSteppedNavWide,
  verticalOrSteppedNavFull,
  React
};