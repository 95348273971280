import React from 'react';

export default function Mail() {
  return (
    <svg width="36" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        transform="scale(-1.22477 .70706) rotate(-45 44.9 59)"
        fill="#000"
        stroke="#000"
        strokeWidth=".3"
        strokeLinejoin="round"
        d="M0 0h29.6v10.8H0z"
      />
      <path
        d="m7 16.2.2.4h0l-.3-.5h0Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m7 16.2.2.4h0l-.3-.5h0ZM10.2 21.2l.2.3h0l-.2-.3h0Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m0 25.3 10.4-3.8-.2-.3-3-4.6-.3-.4L3 10v0L0 5.7v19.5Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.9 10v0l4 6.2.3.4 3 4.6.2.3h0l-.2-.3-3-4.6-.3-.5-4-6ZM15.5 24.4h0l2-.7h0l-2 .7Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m0 25.3 13 7.4 12.8 7.4-10.3-15.7h0l-2.6 1-2.5-3.9h0L0 25.3Z"
        fill="#EFEAE8"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4 21.5h0l2.5 3.8 2.6-.9h0l-2.6.9-2.5-3.8h0ZM25.8 40.1l1.9-1V19.5l-1.8 1v19.6Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m14.8 0-13 4.7-1.7 1L13 1l1.7-1Z"
        fill="#000"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m25.5 20.3.4.3-.2-.3h-.2Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 5.8 26 20.6l-13 4.7L.1 5.8Z"
        fill="#000"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m15.5 24.4 10.3 15.7v-9.7l.1-9.8-2.7 1h0-.1l-5.6 2-2 .8Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 24.4h0l2-.7 5.6-2v-.1h0l-5.6 2-2 .8ZM23.7 19.3l1.8 1 .4.3-.2-.3-2-3h0v2ZM14.8 0 13 1 26 20.6l1.8-1L14.8 0Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9 20.6 13 1 0 5.8 26 20.6Z"
        fill="#65BFB1"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 21.2v.4h.1l.6-.3v-4 0-3.5l-.6.3V21.2Z"
        fill="#000"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 21.6v0h.1Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m2.9 2.6 1.9 1 .4.3h0l11.5 6.6.5.3 3.6 2 2.3 1.3.6-.3-3.6-2-2.2-1.3-.5-.3L5.9 3.6h0l-.4-.2-2-1.2-.6.4Z"
        fill="#000"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m2.9 10 4 6.1.3.5 3 4.6.2.3 2.5 3.8 2.6-1 2-.7 5.6-2v-5.3 0-2.2l-2.3-1.3-3.6-2-.5-.3L5.2 4h0l-.4-.2-1.9-1.1V10Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        transform="matrix(.866 .50003 .00004 1 7.3 11.2)"
        stroke="#000"
        strokeWidth=".3"
        strokeLinejoin="round"
        d="M0 0h9.8v3.5H0z"
      />
      <path
        transform="matrix(.866 .50003 .00004 1 8.7 10.4)"
        fill="#65BFB1"
        stroke="#000"
        strokeWidth=".3"
        strokeLinejoin="round"
        d="M0 0h9.8v3.5H0z"
      />
      <path
        d="M2.9 10v0l4 6.2.3.4 3 4.6.2.3h0l2.5 3.8 2.6-.9h0l2-.7 5.6-2v0l-5.6 2-2 .7-2.6.9-2.5-3.8-.2-.3-3-4.6-.3-.5-4-6Z"
        fill="#FCF9F4"
        stroke="#000"
        strokeWidth=".3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
