import ModalSizePositive from "../../../../src/content/usage-guides/images/modal-size-positive.png";
import ModalSizeNegative from "../../../../src/content/usage-guides/images/modal-size-negative.png";
import ModalSize2Positive from "../../../../src/content/usage-guides/images/modal-size-positive-2.png";
import ModalSize2Negative from "../../../../src/content/usage-guides/images/modal-size-negative-2.png";
import ModalAlignmentPositive from "../../../../src/content/usage-guides/images/modal-alignment-positive.png";
import ModalAlignmentNegative from "../../../../src/content/usage-guides/images/modal-alignment-negative.png";
import ModalSpacingPositive from "../../../../src/content/usage-guides/images/modal-spacing-positive.png";
import ModalSpacingNegative from "../../../../src/content/usage-guides/images/modal-spacing-negative.png";
import ModalDismissPositive from "../../../../src/content/usage-guides/images/modal-dismiss-positive.png";
import ModalDismissNegative from "../../../../src/content/usage-guides/images/modal-dismiss-negative.png";
import ModalCTAPositive from "../../../../src/content/usage-guides/images/modal-cta-positive.png";
import ModalCTANegative from "../../../../src/content/usage-guides/images/modal-cta-negative.png";
import ModalMultipleCTAsPositive from "../../../../src/content/usage-guides/images/modal-multiple-ctas-positive.png";
import ModalMultipleCTAsNegative from "../../../../src/content/usage-guides/images/modal-multiple-ctas-negative.png";
import ModalMultipleCTAExceptionPositive from "../../../../src/content/usage-guides/images/modal-cta-exception-positive.png";
import ModalMultipleCTAExceptionNegative from "../../../../src/content/usage-guides/images/modal-cta-exception-negative.png";
import ModalFullCTAPositive from "../../../../src/content/usage-guides/images/modal-full-cta-positive.png";
import ModalFullCTANegative from "../../../../src/content/usage-guides/images/modal-full-cta-negative.png";
import ModalWideCTAPositive from "../../../../src/content/usage-guides/images/modal-wide-cta-positive.png";
import ModalWideCTANegative from "../../../../src/content/usage-guides/images/modal-wide-cta-negative.png";
import ModalFormStackedPositive from "../../../../src/content/usage-guides/images/modal-form-stacked-positive.png";
import ModalFormStackedNegative from "../../../../src/content/usage-guides/images/modal-form-stacked-negative.png";
import ModalFormStackedExceptionPositive from "../../../../src/content/usage-guides/images/modal-form-stacked-exception-positive.png";
import ModalFormStackedExceptionNegative from "../../../../src/content/usage-guides/images/modal-form-stacked-exception-negative.png";
import ModalFormLayoutPositive from "../../../../src/content/usage-guides/images/modal-form-layout-positive.png";
import ModalFormLayoutNegative from "../../../../src/content/usage-guides/images/modal-form-layout-negative.png";
import ModalFormFullInputPositive from "../../../../src/content/usage-guides/images/modal-form-full-input-positive.png";
import ModalFormFullInputNegative from "../../../../src/content/usage-guides/images/modal-form-full-input-negative.png";
import * as React from 'react';
export default {
  ModalSizePositive,
  ModalSizeNegative,
  ModalSize2Positive,
  ModalSize2Negative,
  ModalAlignmentPositive,
  ModalAlignmentNegative,
  ModalSpacingPositive,
  ModalSpacingNegative,
  ModalDismissPositive,
  ModalDismissNegative,
  ModalCTAPositive,
  ModalCTANegative,
  ModalMultipleCTAsPositive,
  ModalMultipleCTAsNegative,
  ModalMultipleCTAExceptionPositive,
  ModalMultipleCTAExceptionNegative,
  ModalFullCTAPositive,
  ModalFullCTANegative,
  ModalWideCTAPositive,
  ModalWideCTANegative,
  ModalFormStackedPositive,
  ModalFormStackedNegative,
  ModalFormStackedExceptionPositive,
  ModalFormStackedExceptionNegative,
  ModalFormLayoutPositive,
  ModalFormLayoutNegative,
  ModalFormFullInputPositive,
  ModalFormFullInputNegative,
  React
};