import React from 'react';

export default function LightBulb() {
  return (
    <svg width="29" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="3"
        cy="3"
        r="2.9"
        transform="scale(1.22763 .70208) rotate(45 -52.6 38.2)"
        fill="#000"
        stroke="#000"
        strokeWidth=".3"
      />
      <circle
        cx="3"
        cy="3"
        r="2.9"
        transform="scale(1.22763 .70208) rotate(45 -50.6 37.3)"
        fill="#ED914A"
        stroke="#000"
        strokeWidth=".3"
      />
      <circle
        cx="3"
        cy="3"
        r="2.9"
        transform="scale(1.22763 .70208) rotate(45 -49 36.7)"
        fill="#ED914A"
        stroke="#000"
        strokeWidth=".3"
      />
      <circle
        cx="5.6"
        cy="5.6"
        r="5.4"
        transform="scale(1.22763 .70208) rotate(45 -35.4 31)"
        fill="#000"
        stroke="#000"
        strokeWidth=".3"
      />
      <path d="M5.8 25.1a7 7 0 0 1 1.8 3.4M22.5 25.1c-.6.6-1.5 2.6-1.7 4" stroke="#000" strokeWidth=".3" />
      <circle
        cx="5.6"
        cy="5.6"
        r="5.4"
        transform="scale(1.22763 .70208) rotate(45 -32.8 30)"
        fill="#EFEAE8"
        stroke="#000"
        strokeWidth=".3"
      />
      <path
        d="M28 14c0 7.6-6.2 13.8-13.9 13.8S.1 21.6.1 14C.1 6.3 6.4 0 14.1 0s14 6.2 14 13.9Z"
        stroke="#000"
        strokeWidth=".3"
      />
      <circle
        cx="3"
        cy="3"
        r="2.9"
        transform="scale(1.22763 .70208) rotate(45 -36.5 31.5)"
        fill="#000"
        stroke="#000"
        strokeWidth=".3"
      />
      <circle
        cx="3"
        cy="3"
        r="2.9"
        transform="scale(1.22763 .70208) rotate(45 -34.4 30.6)"
        fill="#ED914A"
        stroke="#000"
        strokeWidth=".3"
      />
      <path d="M17.3 13.7v6.7l-5.8 3.3V17" stroke="#000" strokeWidth=".3" strokeLinejoin="bevel" />
      <path stroke="#000" strokeWidth=".3" d="M14.4 22.2v4.2" />
    </svg>
  );
}
