import phoneMaskGif from "../../../../src/content/resources/images/phonemask.gif";
import decimalMaskGif from "../../../../src/content/resources/images/decimalmask.gif";
import integerMaskGif from "../../../../src/content/resources/images/integermask.gif";
import dateMaskGif from "../../../../src/content/resources/images/datemask.gif";
import moneyMaskGif from "../../../../src/content/resources/images/moneymask.gif";
import * as React from 'react';
export default {
  phoneMaskGif,
  decimalMaskGif,
  integerMaskGif,
  dateMaskGif,
  moneyMaskGif,
  React
};