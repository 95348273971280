import { useTableVirtualizer, NewTable, Text, Currency } from '@carta/ink';
import * as React from 'react';
import * as falso from '@ngneat/falso';

const TABLE_ROWS_TO_GENERATE = 1000;

falso.seed('virtual-table');
const data = falso.toCollection(
  () => ({
    id: falso.randUuid().split('-')[0],
    name: falso.randFullName(),
    transactions: falso.toCollection(
      () => ({
        id: falso.randUuid(),
        type: falso.randTransactionType(),
        currency: falso.randCurrencyCode(),
        amount: falso.randAmount({
          min: -10000,
          max: 10000,
          fraction: 2,
        }),
      }),
      { length: falso.randNumber({ min: 1, max: 5 }) },
    ),
    security: `${falso.randAbbreviation()}-${falso.randNumber({
      max: 100,
    })}`,
  }),
  { length: TABLE_ROWS_TO_GENERATE },
);

type OnlyArray<T> = T extends any[] ? T : never;
type OnlyArrayData = OnlyArray<typeof data>;

export const TutorialTable = () => {
  const virtualizer = useTableVirtualizer(data as OnlyArrayData, {
    getItemKey: React.useCallback(index => data[index].id, []),
    getTwiddleRowCount: React.useCallback(item => item.transactions.length, []),
  });

  const widths = {
    id: '317px',
    other: 'calc((100% - 357px) * 0.5)',
  };

  return (
    <NewTable.VirtualWrapper height="540px" {...virtualizer.getWrapperProps()}>
      <NewTable>
        <NewTable.Head>
          <NewTable.Row>
            <NewTable.HeadCell preset="twiddleIcon">
              <NewTable.Twiddle.ExpandAll />
            </NewTable.HeadCell>
            <NewTable.HeadCell width={widths.id}>ID</NewTable.HeadCell>
            <NewTable.HeadCell width={widths.other}>Name</NewTable.HeadCell>
            <NewTable.HeadCell width={widths.other}>Security</NewTable.HeadCell>
          </NewTable.Row>
        </NewTable.Head>
        <NewTable.Body {...virtualizer.getBodyProps()}>
          {virtualizer.getVirtualItems().map(virtualRow => {
            const stakeholder = virtualizer.getRowItem(virtualRow);

            if (virtualRow.type === 'parent') {
              return (
                <NewTable.Twiddle id={stakeholder.id} key={virtualRow.key}>
                  {({ toggle }) => (
                    <NewTable.Row {...virtualizer.getRowProps(virtualRow)}>
                      <NewTable.Cell preset="twiddleIcon">
                        <NewTable.Twiddle.Icon onClick={toggle} />
                      </NewTable.Cell>
                      <NewTable.Cell width={widths.id}>
                        <Text variant="monospace">{stakeholder.id}</Text>
                      </NewTable.Cell>
                      <NewTable.Cell width={widths.other}>{stakeholder.name}</NewTable.Cell>
                      <NewTable.Cell width={widths.other}>{stakeholder.security}</NewTable.Cell>
                    </NewTable.Row>
                  )}
                </NewTable.Twiddle>
              );
            }

            const tnx = stakeholder.transactions[virtualRow.twiddleIndex];
            return (
              <NewTable.Twiddle id={tnx.id} key={virtualRow.key}>
                <NewTable.Row {...virtualizer.getRowProps(virtualRow)}>
                  <NewTable.Cell preset="twiddleIcon" />
                  <NewTable.Cell width={widths.id}>
                    <Text variant="monospace">{tnx.id}</Text>
                  </NewTable.Cell>
                  <NewTable.Cell width={widths.other}>
                    {tnx.type.charAt(0).toUpperCase() + tnx.type.slice(1)}
                  </NewTable.Cell>
                  <NewTable.Cell width={widths.other}>
                    <Currency format="code" code={tnx.currency} value={tnx.amount} colorize />
                  </NewTable.Cell>
                </NewTable.Row>
              </NewTable.Twiddle>
            );
          })}
        </NewTable.Body>
      </NewTable>
    </NewTable.VirtualWrapper>
  );
};
