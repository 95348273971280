import * as React from 'react';
import { NewTable, useTableVirtualizer, useSelectable, useSort, NewCheckbox } from '@carta/ink';
import * as falso from '@ngneat/falso';

const securityTypes = {
  'equity-common': 'Common shares',
  'equity-preferred': 'Preferred shares',
  'equity-convertible': 'Convertible notes',
  'equity-warrant': 'Warrants',
  'equity-option': 'Option grants',
  'equity-pool': 'Option pool',
} as const;

falso.seed('virtual-table');
const numberFactory = falso.incrementalNumber();
const rowCount = 10000;
const data = falso.toCollection(
  () => ({
    id: numberFactory(),
    securityType: falso.rand(Object.keys(securityTypes)),
    name: falso.randFullName(),
    security: `${falso.randAbbreviation()}-${falso.randNumber({
      min: 10,
      max: 100,
    })}`,
  }),
  { length: rowCount },
);

type OnlyArray<T> = T extends any[] ? T : never;
type OnlyArrayData = OnlyArray<typeof data>;

const WIDTHS = {
  id: '80px',
  securityType: 'calc((100% - 120px) / 3)',
  name: 'calc((100% - 120px) / 3)',
  security: 'calc((100% - 120px) / 3)',
};

export const VirtualTable = () => {
  const [selected, selectActions] = useSelectable(data as OnlyArrayData);
  const [sorted, sortActions] = useSort(data as OnlyArrayData, {
    initialState: { key: 'id', direction: 'ascending' },
  });

  const virtualizer = useTableVirtualizer(sorted.items, { overscan: 10 });

  return (
    <NewTable.VirtualWrapper height="400px" {...virtualizer.getWrapperProps()}>
      <NewTable>
        <NewTable.Head>
          <NewTable.Row>
            <NewTable.HeadCell preset="checkbox">
              <NewCheckbox id="select-all" checked={selected.allRows} onChange={selectActions.toggleAllRows} />
            </NewTable.HeadCell>
            <NewTable.HeadCell width={WIDTHS.id}>
              <NewTable.Pin {...sortActions.getPinProps('id')}>ID</NewTable.Pin>
            </NewTable.HeadCell>
            <NewTable.HeadCell width={WIDTHS.securityType}>
              <NewTable.Pin {...sortActions.getPinProps('securityType')}>Type</NewTable.Pin>
            </NewTable.HeadCell>
            <NewTable.HeadCell width={WIDTHS.name}>
              <NewTable.Pin {...sortActions.getPinProps('name')}>Stakeholder</NewTable.Pin>
            </NewTable.HeadCell>
            <NewTable.HeadCell width={WIDTHS.security}>
              <NewTable.Pin {...sortActions.getPinProps('security')}>Security</NewTable.Pin>
            </NewTable.HeadCell>
          </NewTable.Row>
        </NewTable.Head>
        <NewTable.Body {...virtualizer.getBodyProps()}>
          {virtualizer.getVirtualItems().map(virtualRow => {
            const stakeholder = virtualizer.getRowItem(virtualRow);
            return (
              <NewTable.Row {...virtualizer.getRowProps(virtualRow)} selected={selected.rows[stakeholder.id]}>
                <NewTable.Cell preset="checkbox">
                  <NewTable.Stripe variant={stakeholder.securityType as keyof typeof securityTypes} />
                  <NewCheckbox
                    id={stakeholder.id.toString()}
                    onChange={selectActions.toggleRow}
                    checked={selected.rows[stakeholder.id]}
                  />
                </NewTable.Cell>
                <NewTable.Cell width={WIDTHS.id}>{stakeholder.id}</NewTable.Cell>
                <NewTable.Cell width={WIDTHS.securityType}>{securityTypes[stakeholder.securityType]}</NewTable.Cell>
                <NewTable.Cell width={WIDTHS.name}>{stakeholder.name}</NewTable.Cell>
                <NewTable.Cell width={WIDTHS.security}>{stakeholder.security}</NewTable.Cell>
              </NewTable.Row>
            );
          })}
        </NewTable.Body>
      </NewTable>
    </NewTable.VirtualWrapper>
  );
};
