import Bullhorn from "../../../../src/content/resources/images/Bullhorn.tsx";
import LightBulb from "../../../../src/content/resources/images/LightBulb.tsx";
import Mail from "../../../../src/content/resources/images/Mail.tsx";
import Pie from "../../../../src/content/resources/images/Pie.tsx";
import CardAnatomy from "../../../../src/content/resources/images/card-anatomy.png";
import Lifestyle from "../../../../src/content/resources/images/lifestyle-image.png";
import * as React from 'react';
export default {
  Bullhorn,
  LightBulb,
  Mail,
  Pie,
  CardAnatomy,
  Lifestyle,
  React
};