import React from 'react';
import * as Ink from '@carta/ink';
import IconTags from './icon-tags';

const sizes = [
  { name: 'xxs', dimension: 10 },
  { name: 'xs', dimension: 11 },
  { name: 'sm', dimension: 12 },
  { name: 'md', dimension: 14 },
  { name: 'lg', dimension: 16 },
  { name: 'xl', dimension: 19 },
  { name: 'xxl', dimension: 22 },
];

type IconSizeType = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type IconTagType = { name: string; tags: string[] };

type IconTagsType = IconTagType[];

function SizeTable() {
  return (
    <Ink.Tile>
      <Ink.NewTable density="higher">
        <Ink.NewTable.Head>
          <Ink.NewTable.Row>
            <Ink.NewTable.HeadCell>Size name</Ink.NewTable.HeadCell>
            <Ink.NewTable.HeadCell>Dimension (px)</Ink.NewTable.HeadCell>
          </Ink.NewTable.Row>
        </Ink.NewTable.Head>
        <Ink.NewTable.Body>
          {sizes.map(size => (
            <Ink.NewTable.Row key={size.name}>
              <Ink.NewTable.Cell>{size.name}</Ink.NewTable.Cell>
              <Ink.NewTable.Cell>{`${size.dimension}x${size.dimension}`}</Ink.NewTable.Cell>
            </Ink.NewTable.Row>
          ))}
        </Ink.NewTable.Body>
      </Ink.NewTable>
    </Ink.Tile>
  );
}

function IconSearch({
  options,
  setResults,
}: {
  options: IconTagsType;
  setResults: React.Dispatch<React.SetStateAction<IconTagsType>>;
}) {
  const [value, setValue] = React.useState('');
  const handleSearchChange = e => {
    setValue(e.target.value);
    setResults(options.filter(option => option.tags.filter(o => o.includes(e.target.value)).length > 0));
  };

  return (
    <Ink.Field isStacked label="Filter">
      <Ink.NewInput
        id="icon-search"
        placeholder="i.e. document, arrow"
        data-testid="icon-search"
        value={value}
        onChange={e => handleSearchChange(e)}
      />
    </Ink.Field>
  );
}

function IconSizePicker({
  size,
  setSize,
}: {
  size: IconSizeType;
  setSize: React.Dispatch<React.SetStateAction<IconSizeType>>;
}) {
  return (
    <Ink.Field isStacked label="Size">
      <Ink.NewSelect width="half" id="icon-size" value={size} onChange={e => setSize(e.target.value as IconSizeType)}>
        {sizes.map(size => (
          <Ink.NewSelect.Option key={size.name} value={size.name}>
            {size.name}
          </Ink.NewSelect.Option>
        ))}
      </Ink.NewSelect>
    </Ink.Field>
  );
}

export default function IconsUsagePage() {
  const [results, setResults] = React.useState(IconTags);
  const [size, setSize] = React.useState<IconSizeType>('xxl');

  return (
    <Ink.Box>
      <Ink.Row>
        <Ink.Column mobile="1-of-12" tabletPortrait="8-of-12">
          <Ink.VStack>
            <p>
              Icons are small support graphics used to illustrate hard concepts and aid heavy text pages with more
              visual indications. Use icons sparingly and try to make them small.
            </p>
            <p>
              Set the aria-hidden prop to true when using an icon for purely decorative purposes, such as inside a
              button or link. This hides it from screen readers, leaving only meaningful elements.
            </p>
          </Ink.VStack>
        </Ink.Column>
        <Ink.Column mobile="1-of-12" tabletPortrait="4-of-12">
          <SizeTable />
        </Ink.Column>
      </Ink.Row>
      <Ink.Box top="xlarge">
        <Ink.HStack>
          <IconSearch options={IconTags} setResults={setResults} />
          <IconSizePicker size={size} setSize={setSize} />
        </Ink.HStack>
        <Ink.Tiles columns={[3, 4, 6]}>
          {results.map(icon => (
            <Ink.Box vertical="xlarge">
              <Ink.VStack key={icon.name} align="center">
                <Ink.Icon type={icon.name} size={size} />
                <Ink.Box top="small">
                  <Ink.Text align="center">{icon.name}</Ink.Text>
                </Ink.Box>
              </Ink.VStack>
            </Ink.Box>
          ))}
        </Ink.Tiles>
      </Ink.Box>
    </Ink.Box>
  );
}
