const IconTags = [
  {
    name: '2fa',
    tags: ['2fa', 'security', 'mobile', 'device', 'authentication', 'phone'],
  },
  {
    name: '2fa-n',
    tags: ['2fa-n', '2fa', 'security', 'mobile', 'device', 'authentication', 'notification', 'phone'],
  },
  {
    name: 'account-search',
    tags: ['account-search', 'account', 'search', 'find', 'user', 'profile'],
  },
  {
    name: 'account',
    tags: ['account', 'user', 'profile', 'person'],
  },
  {
    name: 'add',
    tags: ['add', 'plus', 'new', 'create'],
  },
  {
    name: 'backward-bold',
    tags: ['backward-bold', 'back', 'previous', 'arrow', 'navigation', 'left', 'chevron'],
  },
  {
    name: 'backward',
    tags: ['backward', 'back', 'previous', 'arrow', 'navigation', 'left', 'chevron'],
  },
  {
    name: 'bank',
    tags: ['bank', 'finance', 'money', 'institution'],
  },
  {
    name: 'box',
    tags: ['box', 'package', 'container', 'storage'],
  },
  {
    name: 'calculator',
    tags: ['calculator', 'math', 'calculation', 'finance'],
  },
  {
    name: 'calendar',
    tags: ['calendar', 'date', 'schedule', 'event'],
  },
  {
    name: 'capitalization',
    tags: ['capitalization', 'finance', 'investment', 'market'],
  },
  {
    name: 'chat',
    tags: ['chat', 'message', 'conversation', 'communication'],
  },
  {
    name: 'checkmark',
    tags: ['checkmark', 'success', 'complete', 'done'],
  },
  {
    name: 'close',
    tags: ['close', 'exit', 'cancel', 'remove'],
  },
  {
    name: 'collapse-all',
    tags: ['collapse-all', 'minimize', 'hide', 'fold'],
  },
  {
    name: 'company',
    tags: ['company', 'business', 'organization', 'corporation'],
  },
  {
    name: 'compliance',
    tags: ['compliance', 'legal', 'regulation', 'rules'],
  },
  {
    name: 'corporation',
    tags: ['corporation', 'business', 'company', 'organization'],
  },
  {
    name: 'credit-card',
    tags: ['credit-card', 'payment', 'finance', 'money'],
  },
  {
    name: 'delete',
    tags: ['delete', 'remove', 'trash', 'erase'],
  },
  {
    name: 'document',
    tags: ['document', 'file', 'paper', 'contract', 'pdf'],
  },
  {
    name: 'dollar',
    tags: ['dollar', 'money', 'currency', 'finance'],
  },
  {
    name: 'down-bold',
    tags: ['down-bold', 'arrow', 'direction', 'downward', 'down', 'chevron'],
  },
  {
    name: 'down-carat',
    tags: ['down-carat', 'arrow', 'dropdown', 'expand', 'down'],
  },
  {
    name: 'down',
    tags: ['down', 'arrow', 'direction', 'downward', 'down', 'chevron'],
  },
  {
    name: 'download',
    tags: ['download', 'save', 'get', 'receive'],
  },
  {
    name: 'drag-n-drop',
    tags: ['drag-n-drop', 'move', 'reorder', 'arrange'],
  },
  {
    name: 'edit',
    tags: ['edit', 'modify', 'change', 'pencil'],
  },
  {
    name: 'error',
    tags: ['error', 'warning', 'alert', 'danger'],
  },
  {
    name: 'exchange',
    tags: ['exchange', 'swap', 'trade', 'transfer'],
  },
  {
    name: 'exit',
    tags: ['exit', 'leave', 'logout', 'quit'],
  },
  {
    name: 'expand-all',
    tags: ['expand-all', 'maximize', 'show', 'unfold'],
  },
  {
    name: 'file-inverted',
    tags: ['file-inverted', 'document', 'paper', 'inverse'],
  },
  {
    name: 'file',
    tags: ['file', 'document', 'paper', 'data'],
  },
  {
    name: 'filter-2',
    tags: ['filter-2', 'sort', 'refine'],
  },
  {
    name: 'filter-1',
    tags: ['filter-1', 'sort', 'refine'],
  },
  {
    name: 'filter',
    tags: ['filter', 'sort', 'refine'],
  },
  {
    name: 'fit-to-page',
    tags: ['fit-to-page', 'reset zoom', 'crop', 'fit', 'box'],
  },
  {
    name: 'forward-bold',
    tags: ['forward-bold', 'next', 'arrow', 'navigation', 'chevron', 'right'],
  },
  {
    name: 'forward',
    tags: ['forward', 'next', 'arrow', 'navigation', 'chevron', 'right'],
  },
  {
    name: 'gear',
    tags: ['gear', 'settings', 'options', 'preferences'],
  },
  {
    name: 'globe',
    tags: ['globe', 'world', 'international', 'global'],
  },
  {
    name: 'group',
    tags: ['group', 'team', 'people', 'users'],
  },
  {
    name: 'hamburger',
    tags: ['hamburger', 'menu', 'navigation', 'options'],
  },
  {
    name: 'hamburger-collapse',
    tags: ['hamburger-collapse', 'menu', 'navigation', 'close'],
  },
  {
    name: 'help',
    tags: ['help', 'question', 'support', 'information'],
  },
  {
    name: 'hide',
    tags: ['hide', 'conceal', 'invisible', 'hidden'],
  },
  {
    name: 'hourglass',
    tags: ['hourglass', 'time', 'wait', 'loading'],
  },
  {
    name: 'information',
    tags: ['information', 'info', 'details', 'about'],
  },
  {
    name: 'instructions',
    tags: ['instructions', 'guide', 'manual', 'directions'],
  },
  {
    name: 'invest',
    tags: ['invest', 'finance', 'money', 'growth'],
  },
  {
    name: 'law',
    tags: ['law', 'legal', 'justice', 'rules'],
  },
  {
    name: 'left-arrow',
    tags: ['left-arrow', 'back', 'previous', 'direction'],
  },
  {
    name: 'loading',
    tags: ['loading', 'wait', 'process', 'spinner'],
  },
  {
    name: 'lock',
    tags: ['lock', 'security', 'private', 'closed'],
  },
  {
    name: 'mail',
    tags: ['mail', 'email', 'message', 'communication'],
  },
  {
    name: 'minus',
    tags: ['minus', 'subtract', 'remove', 'decrease'],
  },
  {
    name: 'more-horizontal',
    tags: ['more-horizontal', 'options', 'menu', 'additional'],
  },
  {
    name: 'more-vertical',
    tags: ['more-vertical', 'options', 'menu', 'additional'],
  },
  {
    name: 'note',
    tags: ['note', 'memo', 'comment', 'write'],
  },
  {
    name: 'open-in-new',
    tags: ['open-in-new', 'external', 'link', 'window'],
  },
  {
    name: 'paperclip',
    tags: ['paperclip', 'attachment', 'file', 'document'],
  },
  {
    name: 'pencil',
    tags: ['pencil', 'edit', 'write', 'modify'],
  },
  {
    name: 'permissions',
    tags: ['permissions', 'access', 'rights', 'security'],
  },
  {
    name: 'phone2',
    tags: ['phone2', 'call', 'contact', 'telephone'],
  },
  {
    name: 'pin',
    tags: ['pin', 'location', 'marker', 'map'],
  },
  {
    name: 'plant',
    tags: ['plant', 'growth', 'nature', 'eco'],
  },
  {
    name: 'play',
    tags: ['play', 'start', 'video', 'audio'],
  },
  {
    name: 'portfolio',
    tags: ['portfolio', 'investments', 'finance', 'assets'],
  },
  {
    name: 'preview',
    tags: ['preview', 'view', 'see', 'show'],
  },
  {
    name: 'print',
    tags: ['print', 'document', 'paper', 'copy'],
  },
  {
    name: 'questionmark',
    tags: ['questionmark', 'help', 'support', 'query'],
  },
  {
    name: 'quickaction',
    tags: ['quickaction', 'fast', 'shortcut', 'action'],
  },
  {
    name: 'right-arrow',
    tags: ['right-arrow', 'next', 'forward', 'direction'],
  },
  {
    name: 'search',
    tags: ['search', 'find', 'lookup', 'magnify'],
  },
  {
    name: 'securities',
    tags: ['securities', 'finance', 'stocks', 'investments'],
  },
  {
    name: 'send',
    tags: ['send', 'submit', 'share', 'deliver'],
  },
  {
    name: 'setup',
    tags: ['setup', 'configure', 'install', 'prepare'],
  },
  {
    name: 'sort-carat',
    tags: ['sort-carat', 'order', 'arrange', 'filter'],
  },
  {
    name: 'sort',
    tags: ['sort', 'order', 'arrange', 'filter'],
  },
  {
    name: 'sort-2',
    tags: ['sort-2', 'order', 'arrange', 'filter'],
  },
  {
    name: 'spaceship',
    tags: ['spaceship', 'launch', 'startup', 'innovation'],
  },
  {
    name: 'star',
    tags: ['star', 'favorite', 'rating', 'bookmark'],
  },
  {
    name: 'starfill',
    tags: ['starfill', 'favorite', 'rating', 'bookmark'],
  },
  {
    name: 'table',
    tags: ['table', 'data', 'grid', 'spreadsheet'],
  },
  {
    name: 'tags',
    tags: ['tags', 'label', 'category', 'classify'],
  },
  {
    name: 'tasklist',
    tags: ['tasklist', 'todo', 'checklist', 'tasks'],
  },
  {
    name: 'transactions',
    tags: ['transactions', 'finance', 'money', 'exchange'],
  },
  {
    name: 'trophy',
    tags: ['trophy', 'award', 'achievement', 'win'],
  },
  {
    name: 'universal-download',
    tags: ['universal-download', 'save', 'get', 'receive'],
  },
  {
    name: 'universal-search',
    tags: ['universal-search', 'find', 'lookup', 'global'],
  },
  {
    name: 'unlock',
    tags: ['unlock', 'open', 'access', 'security'],
  },
  {
    name: 'up-bold',
    tags: ['up-bold', 'arrow', 'direction', 'upward', 'chevron'],
  },
  {
    name: 'up-carat',
    tags: ['up-carat', 'arrow', 'collapse', 'minimize'],
  },
  {
    name: 'up',
    tags: ['up', 'arrow', 'direction', 'upward', 'chevron'],
  },
  {
    name: 'upload',
    tags: ['upload', 'send', 'share', 'upload'],
  },
  {
    name: 'user',
    tags: ['user', 'person', 'profile', 'account'],
  },
  {
    name: 'users',
    tags: ['users', 'people', 'group', 'team'],
  },
  {
    name: 'warning',
    tags: ['warning', 'alert', 'caution', 'danger'],
  },
  {
    name: 'zoom',
    tags: ['zoom', 'magnify', 'enlarge', 'scale'],
  },
  {
    name: 'zoom-out',
    tags: ['zoom', 'magnify', 'zoom out', 'scale', 'reduce'],
  },
];

export default IconTags;
