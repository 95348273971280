import React from 'react';

export default function Pie() {
  return (
    <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="17.4687"
        height="17.4687"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 24.0558 13.9515)"
        stroke="black"
        strokeWidth="0.214218"
        strokeLinejoin="bevel"
        strokeDasharray="3.43 3.43"
      />
      <rect
        width="26.4478"
        height="26.4478"
        transform="matrix(0.866863 0.498547 -0.866863 0.498547 24 1)"
        stroke="black"
        strokeWidth="0.214218"
        strokeLinejoin="bevel"
      />
      <path d="M23.999 27.3721L23.999 27.8745L1.00004 14.6451L1.00002 14.1427L23.999 27.3721Z" fill="black" />
      <path d="M24.0012 27.3726L24.0012 27.875L47 14.6457L47 14.1433L24.0012 27.3726Z" fill="black" />
      <mask id="path-5-inside-1_30054_4757" fill="white">
        <path d="M21.0635 23.7234C24.6118 24.0943 28.273 23.7802 31.4812 22.8298C34.6894 21.8793 37.2661 20.3453 38.8131 18.4649C40.3602 16.5844 40.7914 14.4622 40.0402 12.4261C39.2889 10.39 37.3971 8.55346 34.657 7.20017C31.9169 5.84689 28.481 5.05223 24.8803 4.93901C21.2796 4.82578 17.7146 5.40028 14.7361 6.57374C11.7575 7.74719 9.53134 9.45428 8.40148 11.4312C7.27161 13.4081 7.30098 15.5449 8.48505 17.5112L23.9876 14.4L21.0635 23.7234Z" />
      </mask>
      <path
        d="M21.0635 23.7234C24.6118 24.0943 28.273 23.7802 31.4812 22.8298C34.6894 21.8793 37.2661 20.3453 38.8131 18.4649C40.3602 16.5844 40.7914 14.4622 40.0402 12.4261C39.2889 10.39 37.3971 8.55346 34.657 7.20017C31.9169 5.84689 28.481 5.05223 24.8803 4.93901C21.2796 4.82578 17.7146 5.40028 14.7361 6.57374C11.7575 7.74719 9.53134 9.45428 8.40148 11.4312C7.27161 13.4081 7.30098 15.5449 8.48505 17.5112L23.9876 14.4L21.0635 23.7234Z"
        fill="black"
        stroke="black"
        strokeWidth="0.428436"
        mask="url(#path-5-inside-1_30054_4757)"
      />
      <circle
        cx="8.931"
        cy="8.931"
        r="8.82389"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 23.9499 4.57642)"
        fill="#65BFB1"
        stroke="black"
        strokeWidth="0.214218"
      />
      <mask id="path-7-inside-2_30054_4757" fill="white">
        <path d="M20.7927 22.8005C24.3366 23.2066 28.0135 22.9262 31.2532 22.0028C34.4929 21.0795 37.1143 19.5649 38.7108 17.6938C40.3074 15.8227 40.7899 13.6998 40.0835 11.6543C39.3771 9.60874 37.5213 7.75495 34.8038 6.38039C32.0864 5.00583 28.6593 4.18732 25.0539 4.05181C21.4486 3.9163 17.8665 4.47137 14.8633 5.63092C11.8601 6.79047 9.6036 8.4897 8.44374 10.4651C7.28388 12.4404 7.28549 14.5816 8.44834 16.5564L23.9876 13.5068L20.7927 22.8005Z" />
      </mask>
      <path
        d="M20.7927 22.8005C24.3366 23.2066 28.0135 22.9262 31.2532 22.0028C34.4929 21.0795 37.1143 19.5649 38.7108 17.6938C40.3074 15.8227 40.7899 13.6998 40.0835 11.6543C39.3771 9.60874 37.5213 7.75495 34.8038 6.38039C32.0864 5.00583 28.6593 4.18732 25.0539 4.05181C21.4486 3.9163 17.8665 4.47137 14.8633 5.63092C11.8601 6.79047 9.6036 8.4897 8.44374 10.4651C7.28388 12.4404 7.28549 14.5816 8.44834 16.5564L23.9876 13.5068L20.7927 22.8005Z"
        fill="#FCF9F4"
        stroke="black"
        strokeWidth="0.428436"
        mask="url(#path-7-inside-2_30054_4757)"
      />
      <mask id="path-8-inside-3_30054_4757" fill="white">
        <path d="M21.8206 19.7008C24.1824 19.9714 26.633 19.7845 28.7921 19.1691C30.9512 18.5538 32.6983 17.5443 33.7624 16.2973C34.8264 15.0503 35.148 13.6354 34.6772 12.2722C34.2064 10.9089 32.9696 9.67343 31.1585 8.75733C29.3474 7.84123 27.0633 7.29573 24.6605 7.20541C22.2577 7.1151 19.8704 7.48503 17.8688 8.25783C15.8673 9.03063 14.3634 10.1631 13.5904 11.4796C12.8174 12.7961 12.8185 14.2231 13.5935 15.5393L23.9499 13.5068L21.8206 19.7008Z" />
      </mask>
      <path
        d="M21.8206 19.7008C24.1824 19.9714 26.633 19.7845 28.7921 19.1691C30.9512 18.5538 32.6983 17.5443 33.7624 16.2973C34.8264 15.0503 35.148 13.6354 34.6772 12.2722C34.2064 10.9089 32.9696 9.67343 31.1585 8.75733C29.3474 7.84123 27.0633 7.29573 24.6605 7.20541C22.2577 7.1151 19.8704 7.48503 17.8688 8.25783C15.8673 9.03063 14.3634 10.1631 13.5904 11.4796C12.8174 12.7961 12.8185 14.2231 13.5935 15.5393L23.9499 13.5068L21.8206 19.7008Z"
        fill="#FCF9F4"
        stroke="black"
        strokeWidth="0.428436"
        mask="url(#path-8-inside-3_30054_4757)"
      />
      <rect
        width="4.25766"
        height="4.25766"
        transform="matrix(0.865374 0.501127 -0.865374 0.501127 42.199 9.68433)"
        fill="#65BFB1"
        stroke="black"
        strokeWidth="0.214218"
        strokeLinejoin="bevel"
      />
      <rect
        width="4.25766"
        height="4.25766"
        transform="matrix(0.865374 0.501127 -0.865374 0.501127 42.199 7.6311)"
        fill="#65BFB1"
        stroke="black"
        strokeWidth="0.214218"
        strokeLinejoin="bevel"
      />
      <rect
        width="4.25766"
        height="4.25766"
        transform="matrix(0.865374 0.501127 -0.865374 0.501127 42.199 5.57782)"
        fill="#65BFB1"
        stroke="black"
        strokeWidth="0.214218"
        strokeLinejoin="bevel"
      />
    </svg>
  );
}
